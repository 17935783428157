import { FC } from 'react'
import { CommonProductComponent } from '../ComonProduct'
import { useMySelector } from 'hooks/useMySelector'

import { getParsedMenuItems } from 'featureSliced/shared/utils/getParsedMenuItems'
import { convertToPrice } from 'utils/convertToPrice'
import { UpdateProductFooter } from '../footers/UpdateProductFooter'
import {
  IBucketProduct,
  IProductWithBucketProduct,
} from 'store/reducers/QRMenuBucketReducer/types'

type UpdateProductProps = {
  product: IProductWithBucketProduct
  bucketProduct: IBucketProduct
  closeProduct: () => void
}

export const UpdateProduct: FC<UpdateProductProps> = ({
  product,
  bucketProduct,
  closeProduct,
}) => {
  const currency = useMySelector((state) => state.app.companyData).Currency

  // TODO: duplicates. move to helpers
  const getIncludedItemPrice = (price = 0, count = 0) => {
    if (price && count) {
      return `${count} x ${convertToPrice(price)} ${currency || ''}`
    }
    if (!price && count) {
      return `x ${count}`
    }
    if (price && !count) {
      return `${convertToPrice(price)} ${currency || ''}`
    }
    if (!price && !count) {
      return ''
    }
    return ''
  }

  return (
    <>
      <CommonProductComponent
        product={product}
        kitchenInfoState={JSON.parse(product.selectedKitchenInfo || '{}')}
      />
      {getParsedMenuItems(product?.menuItems)?.map((currentItem: any) => (
        <p
          key={`includedItem_${currentItem.productId}_${currentItem.recordGuid}`}
          style={{
            marginTop: '-40px',
            marginBottom: '40px',
          }}
        >{`${currentItem.name} (${getIncludedItemPrice(
          currentItem.price,
          product?.count
        )})`}</p>
      ))}
      <UpdateProductFooter
        product={product}
        bucketProduct={bucketProduct}
        closeProduct={closeProduct}
      />
      {/* <AddCommonProductFooter
        product={product}
        selectedKitchenInfo={kitchenInfoState[0]}
        amountState={[currentAmount, setCurrentAmount]}
        closeProduct={closeProduct}
        initAmount={currentAmount}
      /> */}
    </>
  )
}
