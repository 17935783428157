import { FC, useEffect, useState } from 'react'
import { RenderIf } from 'components/RenderIf'
import { Button } from 'featureSliced/entities/Button'
import { useMySelector } from 'hooks/useMySelector'
import { useMyDispatch } from 'hooks/useMyDispatch'
import { AmountHandler } from 'featureSliced/widgets/AmountHandler'

import { QRMBucketSlice } from 'store/reducers/QRMenuBucketReducer'
import { TableStatuses } from 'store/reducers/QRMenuTableReducer/types'

import { Divider, FooterContainer } from '../ComonProduct/styled'
import {
  IBucketProduct,
  IProductWithBucketProduct,
} from 'store/reducers/QRMenuBucketReducer/types'

type UpdateProductFooterProps = {
  product: IProductWithBucketProduct
  bucketProduct: IBucketProduct
  closeProduct: () => void
}

export const UpdateProductFooter: FC<UpdateProductFooterProps> = ({
  product,
  bucketProduct,
  closeProduct,
}) => {
  // Hooks
  const dispatch = useMyDispatch()
  const addProductToBucket = QRMBucketSlice.actions.addProductToBucket
  // Store
  const tableStatus = useMySelector((state) => state.qrMenuTable.tableStatus)
  const bookingVariant = useMySelector((state) => state.app.menu.bookingVariant)

  // State
  const [currentAmount, setCurrentAmount] = useState(1)

  // useEffect
  useEffect(() => {
    if (product.count) {
      setCurrentAmount(product.count)
    }
  }, [product.count])

  // Functions
  const getMultiplier = (sellCode: 1 | 2 | 3) => {
    if (sellCode === 2) {
      return 100
    } else {
      return 1
    }
  }

  const getStep = (sellCode: 1 | 2 | 3) => {
    if (sellCode === 3) {
      return 0.1
    } else {
      return 1
    }
  }

  const updateProduct = () => {
    const productToAdd = {
      ...bucketProduct,
      count: currentAmount,
    }
    dispatch(addProductToBucket(productToAdd))
    closeProduct()
  }

  return (
    <RenderIf
      key={`${navigator.onLine}${bookingVariant}${tableStatus}`}
      condition={
        navigator.onLine &&
        bookingVariant === 2 &&
        tableStatus !== TableStatuses.BUSY
      }
    >
      <Divider />
      <FooterContainer>
        <AmountHandler
          multiplier={getMultiplier(product.unitSellCode)}
          step={getStep(product.unitSellCode)}
          currentValue={currentAmount}
          onDecrease={setCurrentAmount}
          onIncrease={setCurrentAmount}
        />
        <Button
          buttonType="common"
          disabled={tableStatus === TableStatuses.BUSY}
          width="100%"
          onClick={updateProduct}
        >
          {/* TODO: translations */}
          Update
        </Button>
      </FooterContainer>
    </RenderIf>
  )
}
